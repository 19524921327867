import {setLS, getLS} from 'devegram-javascript-util';

const baseApiUrl = '/xapi/individual/user';
const state = {
    namespaced: true,
    state: {},
    mutations: {
        SET_STATE (state, params) {
            state[params.key] = params.value
        },
    },
    actions: {
        setState({commit}, params) {
            commit('SET_STATE', params)
        },
    },
};

export default state
